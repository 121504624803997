import { Directive, Renderer2, HostListener } from '@angular/core';
import { JobService } from './job.service';

@Directive({
  selector: '[appScrolljobs]'
})
export class ScrolljobsDirective {

  constructor(private jobService: JobService) {
    if (window.innerWidth < 1200 || this.detectIE()) {
      jobService.display = false;
    }
   }   

   @HostListener('window:resize', ['$event'])
    onResize(event) {
      if (event.target.innerWidth < 1200) {
        this.jobService.display = false;
      } else {
        this.jobService.display = true;
      }
    }
    

  @HostListener("scroll") onscroll() {
    let viewwindow = document.querySelector(".jobsss");
    console.log(viewwindow.getBoundingClientRect().top);
    if (viewwindow.getBoundingClientRect().top < -1800) {
      // you're at the bottom of the page
      this.jobService.isItJobInfoViewWindow = true;
      this.jobService.passActiveId.emit("vito");
    } else {
      this.jobService.jobs.forEach(job => 
        this.checkWhetherOverlap(job.id)
      )
    }
    }

  checkWhetherOverlap(jobId) {
    let elem = document.querySelector("#"+jobId+"job");
    // Andere optie die wellicht stabieler blijkt:
    // let bsc = document.getElementById("#"+jobId+"job");
    // console.log("top "+bsc.offsetTop);
    if (elem.getBoundingClientRect().top < 180) {
      this.jobService.isItJobInfoViewWindow = true;
      this.jobService.passActiveId.emit(jobId);
    }
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}
