import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '.app-jobinfoview-item',
  templateUrl: './jobinfoview-item.component.html',
  styleUrls: ['./jobinfoview-item.component.css']
})
export class JobinfoviewItemComponent implements OnInit {
  @Input() job: {id: string,fromYear: number,toYear: number,headline: string, title: string,description: string};

  constructor() { }

  ngOnInit() {
  }

}
