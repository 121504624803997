import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as t from 'topojson';
import { JobService } from '../job.service';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.css']
})
export class MapviewComponent implements OnInit {
  jobs: {id: string,fromYear: number,toYear: number,title: string,description: string}[];

  public name: string = 'd3';

  constructor(private jobService: JobService) {
    this.jobs = this.jobService.jobs;
    this.jobService.passActiveId.subscribe(activeId => {
        this.onChangeActiveJob(activeId);
    });
  }

  ngOnInit() {

    let width = 600;
    let height = 600;

    let projection = d3.geoMercator();

    let svg = d3.select('.europa').append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('margin-top', 10);
    let path = d3.geoPath()
      .projection(projection);
    let g = svg.append('g');
    g.attr('class', 'map');

    console.log("Start JSON call");

    d3.json("../../assets/map/pofeurope.json")
      .then(function (topology) {
        const geojson = t.feature(topology, topology.objects['pofeurope']);
        projection.fitSize([width, height], geojson);
        g.selectAll('path')
          .data(t.feature(topology, topology.objects.pofeurope).features)
          .enter()
          .append('path')
          .attr('d', path)
          .attr('class', 'countries');
        console.log("End JSON call");
    });

    // d3.json("../../assets/map/studyLocations.geojson")
    d3.json("../../assets/map/studyLocations2.geojson")
    .then(function(data) {
      console.log(data.features);
      // projection.fitSize([svg.attr("width"), svg.attr("height")/2.8], data);
      projection.fitSize([svg.attr("width"), svg.attr("height")/2.1], data);
      svg.append("g")
        .selectAll("g")
        .data(data.features)
        .enter()
          .append("g")
          .append("circle")
          .attr("transform", function(d) {
      // return "translate(" + [projection(d.geometry.coordinates)[0]-30, projection(d.geometry.coordinates)[1]+198] + ")"; 
      return "translate(" + [projection(d.geometry.coordinates)[0]-92, projection(d.geometry.coordinates)[1]+198] + ")"; 
    })
          .attr("r", 5)
          .attr("fill", "black")
          .attr("stroke", "black")
          .attr("class","jobcircles")
          .attr("id", function(d) { return d.properties.job })
          // .on("mouseover", colorMe)
    });

    function colorMe(d) {
      console.log("we are coloring me!");
      var jobs = <HTMLElement[]><any>document.getElementsByClassName("jobcircles");
      for (let job of jobs) {
        job.style.fill = "black";
      }
      document.getElementById(d.properties.job).style.fill = "#fef4de";
      // if (this.iets !== undefined) {
      //   console.log(this.iets);
      //   this.jobServicee.passActiveId.emit("internship");
      // }
    }

  }

  emitMe(jobId: string) {
    this.jobService.passActiveId.emit(jobId);
  }

  onChangeActiveJob(jobId: string) {
    var jobs = <HTMLElement[]><any>document.getElementsByClassName("jobcircles");
    for(let job of jobs) {
      job.style.fill = "black";
      job.setAttribute("r","5");
    }
    if (jobId == "vito") {
      document.getElementById("vito").style.fill = "#fef4de";
      document.getElementById("vito").setAttribute("r","7");
    } 
    else if (jobId == "ai") {
      document.getElementById("ai").style.fill = "#fef4de";
      document.getElementById("ai").setAttribute("r","7");
    }
    else if (jobId == "traineeship") {
      document.getElementById("traineeship").style.fill = "#fef4de";
      document.getElementById("traineeship").setAttribute("r","7");
    }
    else if (jobId == "bsc" || jobId == "msc") {
      document.getElementById("bsc").style.fill = "#fef4de";
      document.getElementById("msc").style.fill = "#fef4de";
      document.getElementById("msc").setAttribute("r","7");
    }
    else {
      var job = document.getElementById(jobId);
      job.style.fill = "#fef4de";
      job.setAttribute("r","7");
    }
  }

}