import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/job.service';

@Component({
  selector: 'app-jobinfoview',
  templateUrl: './jobinfoview.component.html',
  styleUrls: ['./jobinfoview.component.css']
})
export class JobinfoviewComponent implements OnInit {
  jobs: {id: string,fromYear: number,toYear: number,headline: string, title: string,description: string}[];
  activeId: string;
  appendix: string = "job";

  constructor(private jobService: JobService) {}

  ngOnInit() {
    this.jobs = this.jobService.jobs;
    this.jobService.passActiveId.subscribe(activeId => {
      this.activeId = activeId;
      if (!this.jobService.isItJobInfoViewWindow) {
        this.onChangeYear();
      }
      this.jobService.isItJobInfoViewWindow = false;
    });
  }

  onChangeYear() {
    let element = document.querySelector("#bsc"+"job");
    for (var job of this.jobs) {
      if (this.activeId == job.id) {
        element = document.querySelector("#"+job.id+"job");
      }
    }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
    }
  }

}
