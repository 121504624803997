import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  display: boolean = true;
  isItJobInfoViewWindow: boolean = false;
  passActiveId = new EventEmitter<string>();
  startYear: number = 2010;
  nowYear: number = 2022;

  jobs = [
    {
      id: "bsc",
      fromYear: 2010,
      toYear: 2013,
      headline: "2010 - 2013, Wageningen University",
      title: 'BSc Forest and Nature Conservation',
      description: "* minor in System Earth\n * Honous Program\n\
      My BSc program allowed me to immerse into the environmental sciences domain and introduced me to \
      research. The Honours Programme allowed me to experience interdisciplinary teamwork,\
      gave me a broad overview of popular scientific literature and had a strong focus on selfreflection."
    },
    {
      id: "msc",
      fromYear: 2013,
      toYear: 2015,
      headline: "2013 - 2016, Wageningen University",
      title: "MSc Geo-Information Science",
      description: "* extra minor in Software Development\n * extra minor in Environmental Monitoring\n My MSc revolved around two major topics, i.e.\
       GIS - applying spatial statistics and visualizating spatial data - and remote sensing - observing the earth by applying machine learning on\
       satellite, UAV and other spectroscopic data\n\n\
       Languages, technologies and skills: Python, R, Java, MySQL, PostgreSQL, ArcGIS, ERDAS IMAGINE, scientific writing"
    },
    {
      id: "internship",
      fromYear: 2015,
      toYear: 2016,
      headline: "March - August 2015, Institut de recherche pour le développement (IRD)",
      title: "Intern Remote Sensing",
      description: "During my internship I've used PLSR to predict the clay content of a large catchment in Tunisia using\
       hyperspectral imagery of various (spatial- spectral) qualities."
    },
    {
      id: "gapyear",
      fromYear: 2016,
      toYear: 2017,
      headline: "2016 - 2017, Jossy Reynvoet BAR (BE), Canary Islands",
      title: "Gap year",
      description: "During my gap year I lived and worked fulltime on a ranch and surfed & sailed while traveling"
    },
    {
      id: "traineeship",
      fromYear: 2017,
      toYear: 2020,
      headline: "2017 - 2020, Algemene Pensioen Groep (APG)",
      title: "Next Gen IT Trainee & DevOps engineer",
      description: "-- Data Science & Artificial Intelligence\nI worked on a wide variety of short-cycled projects in an innovation setting, such as sentiment analysis using a CNN,\
      a spam filter for a chatbot using an ensemble model and predicting soil properties on the basis of satellite data using XGBoost.\n\n\
      -- Software development & DevOps Engineering\n\
      In these departments I worked in large SAFe / scrum / DevOps teams. In this period I developed an Angular dashboard to visualise\
      team dependencies and worked as a Java developer on the biggest back-end system of our company on the automation of \
      employment-based pension allowance.\n\n\
      Languages, technologies and frameworks: Java, Maven, JUnit, JDBC, Hibernate, Spring, SQL, Moquito, Python, R, HTML, CSS, JavaScript, Angular, JQuery, GIT, SVN, CI, Atlassian tools (Jira, Bamboo, Confluence)"
    },
    {
      id: "ai",
      fromYear: 2020,
      toYear: 2021,
      headline: "2020 - 2021, Universidad de Alcalá de Henares",
      title: "Masters Artificial Intelligence & Deep Learning",
      description: "My second Masters degree in Artificial Intelligence and Deep Learning at the University of Alcalá in Madrid\
      enabled me to develop a stronger (applied) mathematical edge and allowed me to practice my Spanish in the real world instead of through \
      Duolingo and watching Desenfrenadas on Netflix, though not as much as I was hoping for due to Covid. My goal is to combine my environmental engineering \
      background with deep learning to develop technical solutions that tackle humanitarian and environmental problems.\n\n\
      Languages, technologies and frameworks: Python, Tensorflow, Keras, PyTorch"
    },
    {
      id: "vito",
      fromYear: 2021,
      toYear: 2022,
      headline: "2021 - 2022, Vlaamse Instelling voor Technologisch Onderzoek (VITO)",
      title: "Data Scientist / Software Engineer",
      description: "At Vito I worked on a variety of data science projects for the European Space Agency (ESA),\
      such as performing crop classification and land cover classification using a variety of models\
      (Catboost, Random Forest) and also I developed a dashboard for one of our clients."
    }
  ]

}
