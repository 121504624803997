import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skillheader',
  templateUrl: './skillheader.component.html',
  styleUrls: ['./skillheader.component.css']
})
export class SkillheaderComponent implements OnInit {
  summary: string;

  constructor() { }

  ngOnInit() {
    this.summary = "Click here if you'd prefer to download a traditional CV!";
  }

  downloadCV() {
    console.log("Enjoy reading!");
  }

}
