import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/job.service';

@Component({
  selector: 'app-mapheader',
  templateUrl: './mapheader.component.html',
  styleUrls: ['./mapheader.component.css']
})
export class MapheaderComponent implements OnInit {
  yearMargins: { year: number, leftMargin: string, color: string, 
    displayLine: boolean }[];
  jobs: {id: string,fromYear: number,toYear: number,title: string,description: string}[];
  job: {id: string,fromYear: number,toYear: number,title: string,description: string};

  constructor(private jobService: JobService) { }

  ngOnInit() {
    this.yearMargins = [{ year: 2010, leftMargin: "-9em", color: "#fef4de", displayLine: false},
          {year: 2011, leftMargin: "-6em", color: "black", displayLine: false},
          {year: 2012, leftMargin: "-3em", color: "black", displayLine: false},
          {year: 2013, leftMargin: "0em", color: "black", displayLine: false},
          {year: 2014, leftMargin: "3em", color: "black", displayLine: false},
          {year: 2015, leftMargin: "6em", color: "black", displayLine: false},
          {year: 2016, leftMargin: "9em", color: "black", displayLine: false},
          {year: 2017, leftMargin: "12em", color: "black", displayLine: false},
          {year: 2018, leftMargin: "15em", color: "black", displayLine: false},
          {year: 2019, leftMargin: "18em", color: "black", displayLine: false},
          {year: 2020, leftMargin: "21em", color: "black", displayLine: false},
          {year: 2021, leftMargin: "24em", color: "black", displayLine: false},
          {year: 2022, leftMargin: "26em", color: "#fef4de", displayLine: false}
    ]
    // this.yearMargins = []
    // for (var i = this.jobService.startYear; i <= this.jobService.nowYear; i++) {
    //   let marginStep = -9 + (i-this.jobService.startYear)*3;
    //   let col = ([this.jobService.startYear, this.jobService.nowYear].includes(i)) ? "fef4de" : "black";
    //   this.yearMargins.push({ year: i, leftMargin: String(marginStep)+"em", color: col, displayLine: false})
    // }
    // console.log("Hier komtie");
    // console.log(this.yearMargins);

    this.jobs = this.jobService.jobs;
    this.jobService.passActiveId.subscribe(activeId => {
      this.resetYears();
      this.adjustColorsTimelineAccordingToClickedPeriod(activeId);
    });

  }

  onClickYear(year: number) {
    if (year == this.jobService.nowYear) {
      this.jobService.passActiveId.emit("vito");
    } else {
      for(let job of this.jobService.jobs) {
        if ((year >= job.fromYear) && (year <= job.toYear)) {
          console.log(job.id);
          this.jobService.passActiveId.emit(job.id);
        }
      }
    }
  }

  resetYears() {
    for (let year of this.yearMargins) {
      if (year.year !== this.jobService.startYear && year.year !== this.jobService.nowYear) {
        year.color = "black";
      } else {
        year.color = "#fef4de"
      }
    }
  }

  adjustColorsTimelineAccordingToClickedPeriod(jobId: string) {
    this.job = this.findJobById(jobId);
    for(let j=this.jobService.startYear; j<=this.jobService.nowYear; j++) {
      this.yearMargins[j-this.jobService.startYear].displayLine = false;
    }
    for(let i=this.job.fromYear; i<=this.job.toYear; i++) {
      this.yearMargins[i-this.jobService.startYear].color = "#fcd703";
      this.yearMargins[i-this.jobService.startYear].displayLine = (i != this.job.toYear) ? true : false;
    }
  }

  findJobById(jobId: string) {
    for (let job of this.jobs) {
      if (job.id == jobId) {
        return job;
      }
    }
  }
}
