import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skillview',
  templateUrl: './skillview.component.html',
  styleUrls: ['./skillview.component.css']
})
export class SkillviewComponent implements OnInit {
  summary: string;
  skills: { taal: string, perc: number }[];
  languages: { taal: string, perc: number }[];

  constructor() {
   }

  ngOnInit() {
    this.summary = "I am an enthusiastic and inquisitive team player with a background in GIS, remote sensing and data science. I would like to work for WWF because I’m looking to combine my skillset and find an opportunity in which I can build entire products (backend to frontend) related to geographical data";
    this.skills = [{ taal: "Python", perc: 90 },
    { taal: "HTML", perc: 80},
    { taal: "CSS", perc: 60},
    { taal: "Angular", perc: 60},
    { taal: "Java (incl. frameworks)", perc: 70},
    { taal: "R", perc: 70},
      { taal: "Javascript", perc: 50},
      { taal: "ArcGIS / QGIS", perc: 50},
      { taal: "Adobe I / PS", perc: 30}
    ];
    this.languages = [{ taal: "Dutch", perc: 98},
      { taal: "English", perc: 95},
      { taal: "Spanish", perc: 90},
      { taal: "French", perc: 80},
      { taal: "German", perc: 50}
    ];
  }
}
