import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() skill: { taal: string, perc: number };
  baseBarWidth: number = 11;

  constructor() { }

  ngOnInit() {
  }

  calcWidth(div: string, percentage: number) {
    if (div == "skillBar") return (this.baseBarWidth * (percentage/100))+"em";
    if (div == "skillCircle") return ( "calc("+ (this.baseBarWidth * (percentage/100)) +"em - 3px)" );
  }

}