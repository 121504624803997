import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SkillviewComponent } from './skillview/skillview.component';
import { MapviewComponent } from './mapview/mapview.component';
import { JobinfoviewComponent } from './jobinfoview/jobinfoview.component';
import { HeaderComponent } from './header/header.component';
import { SkillheaderComponent } from './header/skillheader/skillheader.component';
import { JobheaderComponent } from './header/jobheader/jobheader.component';
import { MapheaderComponent } from './header/mapheader/mapheader.component';
import { JobinfoviewItemComponent } from './jobinfoview/jobinfoview-item/jobinfoview-item.component';
import { ProgressBarComponent } from './skillview/progress-bar/progress-bar.component';
import { ScrolljobsDirective } from './scrolljobs.directive';
import { MobileviewComponent } from './mobileview/mobileview.component';

@NgModule({
  declarations: [
    AppComponent,
    SkillviewComponent,
    MapviewComponent,
    JobinfoviewComponent,
    HeaderComponent,
    SkillheaderComponent,
    JobheaderComponent,
    MapheaderComponent,
    JobinfoviewItemComponent,
    ProgressBarComponent,
    ScrolljobsDirective,
    MobileviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
